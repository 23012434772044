var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{ 'section section-video': !_vm.test }},[(_vm.$route.query.debug === '1')?_c('p',{staticStyle:{"display":"flex","justify-content":"center"}},[_vm._v(" Buffer: "+_vm._s(_vm.buffer)+" "),_c('br'),_vm._v(" Bitrate: "+_vm._s(_vm.stats)+" ")]):_vm._e(),_c('div',{class:{ container: !_vm.test }},[(_vm.canSkipCheck)?_c('a',{staticClass:"skip",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.skipAd($event)}}},[_vm._v(" "+_vm._s(_vm.$t("video-player-controls.skip"))+" ")]):_vm._e(),_c('player-prevent',{attrs:{"border-radius":"25px","playing":_vm.playing,"fullscreen":_vm.fullscreen,"data":_vm.data},on:{"update:fullscreen":function($event){_vm.fullscreen=$event},"prevent":_vm.pause,"allow":_vm.play}},[(
          _vm.data.contentItems &&
            _vm.data.contentItems.length &&
            _vm.data.price &&
            !_vm.checkPurchased
        )?_c('div',{ref:"videoBlock",staticClass:"video-player"},[_c('img',{staticClass:"poster",attrs:{"src":_vm.poster,"alt":""}}),_c('div',{staticClass:"pay-and-watch"},[(_vm.isBox)?_c('div',{staticClass:"content"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("orders.newToPay"))+" "+_vm._s(JSON.stringify(_vm.data.collections[0].name))+" "+_vm._s(_vm.$t("orders.newToPay2"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("orders.openAccess1"))+" "+_vm._s(JSON.stringify(_vm.data.title))+" "+_vm._s(_vm.$t("orders.openAccess2"))+" ")]),_c('input',{staticClass:"content__button",attrs:{"type":"submit"},domProps:{"value":_vm.$t('orders.newToPay_link')},on:{"click":function($event){$event.preventDefault();return _vm.$emit('showModal', true)}}})]):_c('div',{staticClass:"content"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("orders.newToPayNotBox"))+" "+_vm._s(JSON.stringify(_vm.data.title))+" "+_vm._s(_vm.$t("orders.newToPay2NotBox"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("orders.openAccess1NotBox"))+" "),_c('br'),_vm._v(" "+_vm._s(JSON.stringify(_vm.data.collections[0].name))+" "+_vm._s(_vm.$t("orders.openAccess2NotBox"))+" ")])])])]):(!_vm.isBox)?_c('div',{ref:"videoBlock",staticClass:"video-player"},[_c('img',{staticClass:"poster br-25",attrs:{"src":_vm.poster,"alt":""}})]):_c('div',{ref:"videoBlock",staticClass:"video-player"},[_c('video',{ref:"video",attrs:{"poster":_vm.poster,"playsinline":"","webkit-playsinline":"","autoPlay":false,"controls":false},on:{"ended":_vm.endVideoEvent,"click":_vm.togglePlay}}),(
            _vm.data.contentItems &&
              _vm.data.contentItems.length &&
              _vm.data.isDrm &&
              !_vm.enabledDrm
          )?_c('div',{staticClass:"drm"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("settings.not_active_movie_info"))+" "),_c('router-link',{staticClass:"info",attrs:{"to":"/help"}})],1)]):_vm._e(),(_vm.video)?_c('ShakaPlayer',{ref:"player",attrs:{"video":_vm.video},on:{"tracks":_vm.onGetTracks,"subtitles":_vm.onGetSubtitles,"stats":_vm.onStats,"buffer":_vm.onBuffer,"log":_vm.log,"loaded":_vm.loaded,"loading":_vm.loaded,"ended":_vm.endVideoEvent}}):_vm._e(),(!_vm.playing && _vm.data.contentItems && _vm.data.contentItems.length)?_c('a',{staticClass:"start",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.play($event)}}},[_c('span',[_c('i')])]):_vm._e(),(_vm.data.contentItems && _vm.data.contentItems.length)?_c('controls',{ref:"videoControlsInPlayer",attrs:{"video":this.$refs.video,"video-block":this.$refs.videoBlock,"is-advertise":_vm.isAdvertise,"tracks":_vm.tracks,"subtitles":_vm.subtitles,"data":_vm.data,"disabled-subtitles":_vm.disabledSubtitles,"fullscreen":_vm.fullscreen,"stats":_vm.stats,"buffer":_vm.buffer},on:{"play":_vm.play,"pause":_vm.pause,"update:fullscreen":function($event){_vm.fullscreen=$event},"changeTrack":_vm.onChangeTrack,"changeSubtitles":_vm.onChangeSubtitles,"disabledSubtitles":_vm.onDisabledSubtitles,"timeUpdate":_vm.timeUpdate,"playing":function($event){_vm.playing = $event}}}):_vm._e()],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }