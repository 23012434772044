<template>
  <section :class="{ 'section section-video': !test }">
    <p
      style="display: flex;justify-content: center"
      v-if="$route.query.debug === '1'"
    >
      Buffer: {{ buffer }} <br />
      Bitrate: {{ stats }}
    </p>
    <div :class="{ container: !test }">
      <a href="#" class="skip" @click.prevent="skipAd" v-if="canSkipCheck">
        {{ $t("video-player-controls.skip") }}
      </a>
      <player-prevent
        border-radius="25px"
        :playing="playing"
        :fullscreen.sync="fullscreen"
        @prevent="pause"
        @allow="play"
        :data="data"
      >
        <div
          class="video-player"
          ref="videoBlock"
          v-if="
            data.contentItems &&
              data.contentItems.length &&
              data.price &&
              !checkPurchased
          "
        >
          <img :src="poster" class="poster" alt="" />
          <div class="pay-and-watch">
            <div class="content" v-if="isBox">
              <p>
                {{ $t("orders.newToPay") }}
                {{ JSON.stringify(data.collections[0].name) }}
                {{ $t("orders.newToPay2") }}
              </p>
              <p>
                {{ $t("orders.openAccess1") }} {{ JSON.stringify(data.title) }}
                {{ $t("orders.openAccess2") }}
              </p>
              <input
                type="submit"
                class="content__button"
                @click.prevent="$emit('showModal', true)"
                :value="$t('orders.newToPay_link')"
              />
            </div>
            <div class="content" v-else>
              <p>
                {{ $t("orders.newToPayNotBox") }}
                {{ JSON.stringify(data.title) }}
                {{ $t("orders.newToPay2NotBox") }}
              </p>
              <p>
                {{ $t("orders.openAccess1NotBox") }}
                <br>
                {{ JSON.stringify(data.collections[0].name) }}
                {{ $t("orders.openAccess2NotBox") }}
              </p>
            </div>
          </div>
        </div>
        <div class="video-player" ref="videoBlock" v-else-if="!isBox">
          <img :src="poster" class="poster br-25" alt="" />
          <!-- <span class="play-btn"><i ></i></span> -->
        </div>
        <div class="video-player" ref="videoBlock" v-else>
          <video
            ref="video"
            @ended="endVideoEvent"
            :poster="poster"
            playsinline
            webkit-playsinline
            @click="togglePlay"
            :autoPlay="false"
            :controls="false"
          ></video>
          <div
            class="drm"
            v-if="
              data.contentItems &&
                data.contentItems.length &&
                data.isDrm &&
                !enabledDrm
            "
          >
            <p>
              {{ $t("settings.not_active_movie_info") }}
              <router-link to="/help" class="info"></router-link>
            </p>
          </div>
          <ShakaPlayer
            v-if="video"
            ref="player"
            :video="video"
            v-on:tracks="onGetTracks"
            v-on:subtitles="onGetSubtitles"
            v-on:stats="onStats"
            v-on:buffer="onBuffer"
            v-on:log="log"
            v-on:loaded="loaded"
            v-on:loading="loaded"
            v-on:ended="endVideoEvent"
          />
          <a
            href=""
            class="start"
            v-if="!playing && data.contentItems && data.contentItems.length"
            @click.prevent="play"
            ><span><i></i></span
          ></a>
          <controls
            ref="videoControlsInPlayer"
            v-if="data.contentItems && data.contentItems.length"
            :video="this.$refs.video"
            :video-block="this.$refs.videoBlock"
            :is-advertise="isAdvertise"
            v-on:play="play"
            v-on:pause="pause"
            :tracks="tracks"
            :subtitles="subtitles"
            :data="data"
            :disabled-subtitles="disabledSubtitles"
            :fullscreen.sync="fullscreen"
            @changeTrack="onChangeTrack"
            @changeSubtitles="onChangeSubtitles"
            @disabledSubtitles="onDisabledSubtitles"
            @timeUpdate="timeUpdate"
            @playing="playing = $event"
            :stats="stats"
            :buffer="buffer"
          ></controls>
        </div>
      </player-prevent>
    </div>
  </section>
</template>

<script>
import shaka from "shaka-player";
import ShakaPlayer from "@/components/player/ShakaPlayer";
import Controls from "@/components/player/Controls";
import time from "@/mixins/time";
import cover from "@/mixins/cover";
import { mapMutations, mapState } from "vuex";
import events from "@/mixins/events";
import PlayerPrevent from "@/components/PlayerPrevent";

export default {
  name: "Player",
  components: {
    Controls,
    ShakaPlayer,
    PlayerPrevent
  },
  mixins: [time, cover, events],
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    advertiseData: {
      type: Object,
      default: () => {}
    },
    episodes: {
      type: Number,
      default: () => 0
    },
    test: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      logs: [],
      isAdvertise: false,
      initialPlay: true,
      playing: false,
      probe: null,
      video: null,
      tracks: [],
      subtitles: [],
      stats: {},
      buffer: "0",
      controlkey: 0,
      adRollsAndVideo: [],
      midRoll: [],
      midRollInterval: [],
      midRollPlayCount: 0,
      middleAd: [],
      disabledSubtitles: true,
      canSkip: 0,
      fullscreen: false
    };
  },
  computed: {
    ...mapState("player", {
      adShow: state => state.showAdIds
    }),
    ...mapState("order", {
      purchasedContent: state => state.purchasedContent
    }),
    ...mapState("settings", {
      enabledDrm: state => state.enabledDrm,
      isBox: state => state.isBox
    }),

    canSkipCheck() {
      if (this.canSkip) {
        return this.currentTimeWatch > this.canSkip;
      }
      return false;
    },
    poster() {
      if (!this.initialPlay) {
        return "";
      } else if (this.data.cover) {
        return this.replaceCoverImage(this.data.cover);
      }
      return "";
    },
    checkPurchased() {
      if (this.data.paymentType === "collection") {
        return (
          this.purchasedContent.length &&
          !!this.purchasedContent
            .filter(
              item =>
                item.payloadContent &&
                item.payloadContent.content.paymentType ===
                  this.data.paymentType &&
                item.state === "completed"
            )
            .find(
              item =>
                item.payloadContent &&
                item.payloadContent.content.ids.find(it =>
                  this.data.collections.find(mov => mov.collectionId === it)
                )
            )
        );
      }
      return (
        this.purchasedContent.length &&
        !!this.purchasedContent
          .filter(
            item =>
              item.payloadContent &&
              item.payloadContent.content.paymentType === this.data.paymentType
          )
          .find(
            item =>
              item.payloadContent &&
              item.payloadContent.content.ids.find(it => this.data.id === it)
          )
      );
    }
  },
  created() {},
  watch: {
    episodes: function() {
      this.$nextTick(async () => {
        this.adRollsAndVideo = [];
        await this.init();
        this.$refs.player.load(
          this.adRollsAndVideo[0].url,
          this.adRollsAndVideo[0].isDrm,
          this.adRollsAndVideo[0].startTime
        );
        this.play();
      });
    },
    video: function() {
      this.$nextTick(async () => {
        await this.init();
      });
    },
    currentTimeWatch(value) {
      // if (
      //   this.adRollsAndVideo[0].isDrm &&
      //   !(
      //     this.$browserDetect.isSafari ||
      //     this.$browserDetect.isIOS ||
      //     this.$browserDetect.isChromeIOS
      //   )
      // ) {
      this._streamingEvent(
        this.adRollsAndVideo[0].contentId,
        this.adRollsAndVideo[0].cdbId,
        this.adRollsAndVideo[0].position,
        value,
        5
      );
      // }
      let check = value / this.midRollInterval;
      if (
        this.midRoll.length &&
        this.adRollsAndVideo[0].main &&
        check - this.midRollPlayCount >= 1
      ) {
        this.midRollPlayCount++;
        this.adRollsAndVideo[0].startTime = value;
        for (let i = this.midRoll.length; i > 0; i--) {
          this.adRollsAndVideo.unshift(this.midRoll[i - 1]);
        }
        this.$refs.player.load(
          this.adRollsAndVideo[0].url,
          this.adRollsAndVideo[0].isDrm,
          this.adRollsAndVideo[0].startTime
        );
      }
    }
  },
  mounted() {
    this.video = this.$refs.video;
    // console.log(this);
  },
  methods: {
    ...mapMutations("player", {
      setPlayerEntity: "ADD_ENTITY"
    }),
    ...mapMutations("settings", {
      setSettingsEntity: "SET_ENTITY"
    }),
    timeUpdate(event) {
      this.currentTimeWatch = event;
    },
    checkIsIOSMore16() {
      if (this.$browserDetect.isSafari || this.$browserDetect.isIOS) {
        return (
          navigator.userAgent.includes("Version/16.") ||
          navigator.userAgent.includes("Version/17.") ||
          navigator.userAgent.includes("Version/18.") ||
          navigator.userAgent.includes("Version/19.")
        );
      }
      return false;
    },
    skipAd() {
      if (this.adRollsAndVideo[0].main) {
        this._playMovieEvent(
          this.adRollsAndVideo[0].contentId,
          this.adRollsAndVideo[0].cdbId,
          this.adRollsAndVideo[0].position
        );
      } else {
        this._playAdvertiseEvent(
          this.adRollsAndVideo[0].adVideoId,
          this.adRollsAndVideo[0].contentId,
          this.adRollsAndVideo[0].cdbId,
          this.adRollsAndVideo[0].position,
          this.adRollsAndVideo[0].rollPackId,
          true
        );
      }
      let splice = this.adRollsAndVideo.splice(0, 1);
      if (splice[0].uid && !this.test) {
        this.setPlayerEntity(["showAdIds", splice[0]]);
      }
      if (this.adRollsAndVideo.length) {
        this.$refs.player.load(
          this.adRollsAndVideo[0].url,
          this.adRollsAndVideo[0].isDrm,
          this.adRollsAndVideo[0].startTime
        );
      } else {
        this.$emit("endplaylist", true);
      }
    },
    endVideoEvent() {
      if (!this.adRollsAndVideo[0].main) {
        this._playAdvertiseEvent(
          this.adRollsAndVideo[0].adVideoId,
          this.adRollsAndVideo[0].contentId,
          this.adRollsAndVideo[0].cdbId,
          this.adRollsAndVideo[0].position,
          this.adRollsAndVideo[0].rollPackId
        );
      }
      if (!this.adRollsAndVideo[0].main) {
        let splice = this.adRollsAndVideo.splice(0, 1);
        if (splice[0].uid && !this.test) {
          //добавление видео в просмотреные чтобы не показывать рекламу
          // this.setPlayerEntity(["showAdIds", splice[0]]);
        }
      }
      const { video } = this.$refs.player;
      if (!this.test) {
        video.style.setProperty("height", video.offsetHeight + "px");
      }

      if (this.adRollsAndVideo.length) {
        if (this.adRollsAndVideo[0].main) {
          this._playMovieEvent(
            this.adRollsAndVideo[0].contentId,
            this.adRollsAndVideo[0].cdbId,
            this.adRollsAndVideo[0].position
          );
        }
        this.$refs.player.load(
          this.adRollsAndVideo[0].url,
          this.adRollsAndVideo[0].isDrm,
          this.adRollsAndVideo[0].startTime
        );
      } else {
        this.$emit("endplaylist", true);
      }
    },
    async init() {
      this.log("[player] - init");
      const support = await shaka.Player.probeSupport();
      let url = "streamHls";
      try {
        if (
          (support.manifest.mpd && this.$browserDetect.isChrome) ||
          !this.data.contentItems[this.episodes][url]
        ) {
          url = "stream";
        }
      } catch (e) {
        console.log(e);
      }
      try {
        this.advertiseData.adRolls.preroll.adVideos.forEach(item => {
          let key = this.$route.fullPath + "|" + item.id;

          if (this.adShow.findIndex(it => it.uid === key) === -1) {
            this.adRollsAndVideo.push({
              url: item.stream,
              startTime: 0,
              isDrm: this.data.isDrm,
              main: false,
              uid: this.$route.fullPath + "|" + item.id,
              canSkipAfter: item.canSkipAfter,
              contentId: this.data.id || -1,
              cdbId: this.data.cdbId || -1,
              position: item.position || -1,
              rollPackId: this.advertiseData.adRolls.preroll.id || -1,
              adVideoId: item.id || -1
            });
          }
        });
      } catch (e) {
        this.log(["Empty Ad Roll"]);
      }

      try {
        this.advertiseData.adRolls.midrolls.adVideos.forEach(item => {
          let key = this.$route.fullPath + "|" + item.id;

          if (this.adShow.findIndex(it => it.uid === key) === -1) {
            this.midRoll.push({
              url: item.stream,
              startTime: 0,
              isDrm: this.data.isDrm,
              main: false,
              uid: this.$route.fullPath + "|" + item.id,
              contentId: this.data.id || -1,
              cdbId: this.data.cdbId || -1,
              position: item.position || -1,
              rollPackId: this.advertiseData.adRolls.midrolls.id || -1,
              adVideoId: item.id || -1
            });
          }
        });
      } catch (e) {
        this.log(["Empty Mid Roll"]);
      }
      try {
        this.midRollInterval = this.advertiseData.adRolls.midrollPeriod * 60;
      } catch (e) {
        this.log(["Empty Mid Roll interval"]);
      }
      if (!this.test) {
        try {
          this.adRollsAndVideo.push({
            url: this.data.disclaimerStream,
            startTime: 0,
            isDrm: false,
            main: false,
            contentId: this.data.id,
            cdbId: this.data.cdbId,
            position: -1,
            rollPackId: -1,
            adVideoId: -1
          });
        } catch (e) {
          this.log(["Empty Disclaimer Roll"]);
        }
      }

      try {
        this.adRollsAndVideo.push({
          url: this.data.contentItems[this.episodes][url],
          startTime: 0,
          isDrm: this.data.isDrm,
          main: true,
          contentId: this.data.id,
          cdbId: this.data.cdbId,
          position: this.data.contentItems[this.episodes].position || -1
        });
      } catch (e) {
        this.log(["Empty Main Data "]);
      }
      if (this.test) {
        // this.play();
      }
    },
    probeData() {
      this.log("[player] - probeSupport");

      // utils.probeSupport().then(data => {
      //   this.probe = data;
      //   console.log(this.probe);
      // });
    },
    unload() {
      this.$refs.player.unload();
    },
    play() {
      const { video } = this.$refs.player;
      video.controls = false;

      if (!this.test) {
        video.style.setProperty("height", video.offsetHeight + "px");
      }

      if (this.initialPlay) {
        this.$refs.player.load(
          this.adRollsAndVideo[0].url,
          this.adRollsAndVideo[0].isDrm,
          this.adRollsAndVideo[0].startTime
        );
        this.initialPlay = false;

        if (this.checkIsIOSMore16()) {
          setTimeout(() => {
            this.pause();
            this.play();
          }, 100);
        }
      }

      video.currentTime = this.currentTimeWatch + 0.002;
      video.play();

      if (!this.isAdvertise) {
        this.startTimeWatch();
      }
    },
    pause() {
      const { video } = this.$refs.player;
      this.stopTimeWatch();
      video.pause();
    },
    togglePlay() {
      if (this.data.contentItems.length) {
        if (this.checkIsAndroid()) {
          if (!this.$refs.videoControlsInPlayer.showing) {
            this.$refs.videoControlsInPlayer.showControls();
          } else {
            this.$refs.videoControlsInPlayer.hideControls();
          }
          return;
        }
        if (this.playing) {
          this.pause();
        } else {
          this.play();
        }
      }
    },
    checkIsAndroid() {
      return (
        window.innerWidth < 900 &&
        !this.$browserDetect.isIOS &&
        !this.$browserDetect.isSafari
      );
    },
    loaded() {
      if (!this.adRollsAndVideo[0].main) {
        this.isAdvertise = true;
        this.stopTimeWatch();
      } else {
        this.startTimeWatch();
        this.isAdvertise = false;
      }
      const { video } = this.$refs.player;

      try {
        if (!this.initialPlay) {
          video.play();
        }
        if (this.test) {
          this.canSkip = this.adRollsAndVideo[0].canSkipAfter;
          video.play();
        }
      } catch (e) {
        console.log(e);
      }
    },

    // Common player events.
    onGetTracks(event) {
      this.log("[player] - onGetTracks");
      this.tracks = event;
    },
    onChangeTrack(event) {
      this.log("[player] - onChangeTrack", event);
      this.tracks.forEach(item => {
        item.active = item.id === event.id;
      });
      event.active = true;
      this.$refs.player.selectTrack(event);
    },
    onGetSubtitles(event) {
      this.log("[player] - onGetSubtitles");
      this.subtitles = event;
    },
    onChangeSubtitles(event) {
      this.log("[player] - onChangeSubtitles", event);
      this.subtitles.forEach(item => {
        item.active = false;
      });
      event.active = true;
      this.disabledSubtitles = false;
      this.$refs.player.selectSubtitles(event);
    },
    onDisabledSubtitles(event) {
      this.subtitles.forEach(item => {
        item.active = false;
      });
      this.disabledSubtitles = true;
      this.$refs.player.disabledSubtitles(event);
    },
    onEnableAdaptation(event) {
      this.log("[player] - onEnableAdaptation", event);
      this.$refs.player.enableAdaptation(event);
    },
    onStats(event) {
      // this.log('[player] - onStats');
      this.stats = event;
    },
    onBuffer(event) {
      // this.log('[player] - onBuffer');
      this.buffer = event;
    },

    // Logger.
    log(...message) {
      if (message.length > 1) {
        if (message[1].category === 6 && message[1].code === 6001) {
          this.setSettingsEntity(["enabledDrm", false]);
        }
        if (message[1].category === 1 && message[1].code > 1000) {
          this._videoPlayerErrorEvent(
            this.adRollsAndVideo[0].contentId,
            this.adRollsAndVideo[0].cdbId,
            this.adRollsAndVideo[0].position,
            message[1].data
          );
        }
      } // eslint-disable-line no-console
      // logs gets updated and passed to <Log /> prop.
      this.logs = this.logs.concat(message.join(" "));
    }
  }
};
</script>

<style scoped>
video {
  object-fit: inherit;
}
.controls-bar {
  display: none !important;
}
.video-player::-webkit-media-controls {
  display: none !important;
}
.player {
  text-align: left;
}

.player .video-container {
  /* height: 480px; */
  margin: 0 auto;
}

.player video {
  background: #aaa;
  height: 100%;
  width: 100%;
}

.player button {
  margin: 0 2px;
}

.player-buttons {
  display: inline-block;
  margin-bottom: 10px;
}

.player-buttons span {
  margin-left: 10px;
}
:-moz-full-screen #controls {
  color: white;
  position: absolute;
  bottom: 0;
}

:-webkit-full-screen #controls {
  color: white;
  position: absolute;
  bottom: 0;
}
:-webkit-full-screen #controls {
  color: white;
  position: absolute;
  bottom: 0;
}

:-ms-full-screen #controls {
  color: white;
  position: absolute;
  bottom: 0;
}

:fullscreen #controls {
  color: white;
  position: absolute;
}

:-moz-full-screen video {
  width: 100%;
  height: 100%;
}

:-webkit-full-screen video {
  width: 100%;
  height: 100%;
}

:-ms-full-screen video {
  width: 100%;
  height: 100%;
}

:fullscreen video {
  width: 100%;
  height: 100%;
}
video::-webkit-media-controls {
  opacity: 0;
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
@media (-webkit-video-playable-inline) {
  video {
    display: initial;
  }
}
:-webkit-full-screen {
  border-radius: 0px !important;
  width: 100%;
}
:-webkit-full-screen video {
  width: 100%;
  border-radius: 0px !important;
}
</style>
